import React from "react"
import {graphql} from "gatsby";
import NotFound from "../../templates/pages/404";

export default ({data}) => <NotFound data={data} isEn={true} />
export const pageQuery = graphql`
    query EnNotFound {
        blog: allMarkdownRemark {
            posts: nodes { 
                frontmatter {
                    title
                    date
                    slug
                    author
                    excerpt
                    thumbnail
                    tag
                }
                html
            }
        }
    }
`
