import React from "react"
import Layout from "@layout/layout"
import SEO from "@layout/seo"
import Hero from "@homepage/hero"
import SasaBox from "@homepage/sasa-box"
import Voluntary from "@homepage/voluntary"
import Local from "@homepage/local"
import Help from "@homepage/help"
import Blog from "@homepage/blog"
import Meet from "@homepage/meet"
import Partners from "@homepage/partners"

import HeaderHome from "@layout/header-home"
import {graphql} from "gatsby";
import PIT from "../../components/homepage/pit";

export default ({data, isEn}) => (
  <Layout isEn={isEn}>
    <HeaderHome isEn={isEn}/>
    <SEO isEn={isEn}/>
    <Hero isEn={isEn}/>
    <div className="whitebg">
      {!isEn && <PIT />}
      <SasaBox isEn={isEn}/>
      <Voluntary isEn={isEn}/>
      <Local isEn={isEn}/>
      <Help isEn={isEn}/>
      <Blog posts={data.blog.posts} />
    </div>
    <Meet isEn={isEn}/>
    <Partners isEn={isEn}/>
  </Layout>
)

export const pageQuery = graphql`
    query EnNotFoundHomepageBlog {
        blog: allMarkdownRemark {
            posts: nodes {
                frontmatter {
                    title
                    date
                    slug
                    author
                    excerpt
                    thumbnail
                    tag
                }
                html
            }
        }
    }
`
